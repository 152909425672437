import React from "react"
import { withTrans } from "../i18n/withTrans"
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import "./theme.css";
import "./fonts.css";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CSSPlugin } from 'gsap/CSSPlugin';
import Footer from "../components/Footer"
import CookiesNotification from "../components/CookiesNotification"

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger, CSSPlugin);
}

const theme = {
    primary: '#000'
}

const GlobalStyle = createGlobalStyle`
    
    * {
        box-sizing: border-box;
    }
  
    body {
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        //font-size: 112.5%;
         font-size: 100%;
        line-height: 1.66;
    }
    
    main {
        min-height: calc(100vh - 584px);
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 800;
    }
    
    h3 {
        margin-bottom: 0;
    }
    
    p, img {
        margin-bottom: 0;
    }
    
    ol {
        margin: 0;
    }
    
    input, textarea {
        font: inherit;
    }
    
    ::-webkit-scrollbar {
        width: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: var(--textNormal);
        border-radius: 6px;
        border: 0;
        opacity: .5;
    }
    
    ::-webkit-scrollbar-track {
        background: var(--themeSwitcherHandle);
        opacity: .5;
    }
`;

const BlogPostLayout = ({ children }) => {

    // useEffect(() => {
    //     gsap.fromTo('.burger_wrapper', { x: '100%', autoAlpha: 0 }, { x: 0, autoAlpha: 1 })
    // }, []);

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            {children}
            <CookiesNotification />
            <Footer narrower />
        </ThemeProvider>
    )
}

export default withTrans(BlogPostLayout);
