import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import FormContext from "../components/ValuationForm/FormContext"
import BlogPostLayout from "../layout/blogPostLayout";
import Seo from "../components/seoComponent"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import SocialMediaShareButtons from "../components/SocialMediaShareButtons"
import { BlogPostHeader, Comments, Avatar, shortcodes, OpenTableOfContentsTop, OpenTableOfContentsFloating } from "../components/BlogPost"
import checkUserPreferredColorScheme from "../utils/checkUserPreferredColorScheme"
import DateIcon from '../../static/date.svg';
import TimeIcon from '../../static/time.svg';
import formatDate from "../utils/formatDate";
import useSiteMetadata from "../hooks/useSiteMetadata"

const ArticleContainer = styled.div`
    //position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 8rem 0 0;
    text-align: unset;
    background: #f3f3f3;
    color: #585858;

    &.dark {
        background: #29323d;
        color: #e1e1e1;

        .table_of_contents {
            border: 1px solid #3c3c3c;
            color: inherit;
            background: #1e2126;
            @supports (backdrop-filter: blur(15px)) {
                & {
                    backdrop-filter: blur(15px);
                    background: rgba(30, 33, 38, 0.8);
                }
            }

            .close_table_of_contents_button_wrapper {
                background: var(--black);
                border: 1px solid var(--white);

                button::before, button::after {
                    background-color: var(--white);
                }
            }
        }

    }

    .table_of_contents {
        border: 1px solid #e8e8e8;
        color: inherit;
        transform: ${({ tableOfContentsVisible }) => tableOfContentsVisible ? 'translate(0, 0)' : 'translate(100%, 0)'};
        transition: transform .3s ease-out;
        will-change: transform;
        background: #ebebeb;
        @supports (backdrop-filter: blur(15px)) {
            & {
                backdrop-filter: blur(15px);
                background: rgba(235, 235, 235, 0.8);
            }
        }

        .close_table_of_contents_button_wrapper {
            background: var(--white);
            border: 1px solid var(--black);
        }
    }

    article {
        max-width: 65ch;
        margin: 0 auto 6rem;
        //color: var(--themeText);
        color: inherit;


        h1, h2, h3 {
            margin: 0;
            color: inherit;
        }

        @media (max-width: 768px) {
            padding: 0 1rem;
        }

        a {
            position: relative;
            color: inherit;
            font-weight: bold;
            text-decoration: underline;
            text-decoration-color: var(--primary);
            text-decoration-thickness: 3px;
            text-decoration-skip-ink: none;
        }
    }

    p {
        color: inherit;
        max-width: 65ch;
        margin: 16px 0;
    }

    ol {
        margin-top: 16px;
        margin-left: 1.666rem;
        color: inherit;

        li::marker {
            font-weight: bold;
        }
    }

    &.dark blockquote {
        background: rgb(26, 33, 41);
    }

    blockquote {
        margin: 16px 0 16px 0;
        padding: .4rem .88rem .4rem 1.63rem;
        background: rgb(233, 233, 233);
        border-radius: 16px;
        position: relative;

        &::before {
            position: absolute;
            content: "💡";
            left: 0;
            top: 0;
            font-size: 3rem;
            transform: translate(-50%, 0);
        }
    }

    hr {
        margin: 20px 0 0;
        height: 2px;
        border-radius: 20px;
        background-color: var(--navLinkColor);
    }

    .gatsby-resp-image-wrapper {
        margin: 20px 0;
    }

    @media (max-width: 768px) {
        padding: 6rem 0 0;
    }
`;

const PostMetadata = styled.div`
    display: flex;
        flex-wrap: wrap;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem;
    margin: 1rem 0;
    
    span {
        color: inherit;
        font-size: 75%;
    }
    
    > div {
        display: flex;
        align-items: center;
        
        svg {
            height: 22px;
            width: auto;
            fill: var(--primary);
            margin-right: .5em;
        }
    }
`;

const BlogTemplate = ({ data }) => {

    const { windowGlobal } = useContext(FormContext);
    const [showTableOfContents, setShowTableOfContents] = useState(false);
    const [darkMode, toggleDarkMode] = useState(false);
    const prefersDarkMode = checkUserPreferredColorScheme('dark');


    useEffect(() => {
        prefersDarkMode === true && toggleDarkMode(true);
    }, [prefersDarkMode]);

    const post = data.mdx;
    const date = formatDate(post.frontmatter.date, post.frontmatter.lang);
    const blogPost = post.frontmatter;

    const image = post.frontmatter.featuredImage
      ? post.frontmatter.featuredImage.childImageSharp.resize
      : null

    return (
        <BlogPostLayout>
            <Seo
                title={`${blogPost.title}`}
                image={image}
                description={blogPost.description || post.excerpt}
                blogPost
                websiteUrl={useSiteMetadata().siteUrl}
                jsonLdUrl={blogPost.url}
                jsonLdHeadline={blogPost.title}
                jsonLdDescription={blogPost.description || post.excerpt}
                jsonLdDatePublished={blogPost.date}
            />
            {/*<ScrollProgressIndicator element={'.blog-post'} />*/}
            <div className="top_anchor"/>
            <MDXProvider components={shortcodes}>
                <BlogPostHeader darkModeOn={darkMode} toggleDarkMode={toggleDarkMode} windowGlobal={windowGlobal} />
                <ArticleContainer className={darkMode ? 'dark' : ''} tableOfContentsVisible={showTableOfContents}>
                    <article className="blog-post" onClick={() => {showTableOfContents && setShowTableOfContents(!showTableOfContents)}}>
                        {/*<StyledArrowWrapper to="/blog">*/}
                        {/*    <LeftArrow />*/}
                        {/*</StyledArrowWrapper>*/}
                        <h1 className="title">{blogPost.title}</h1>
                        <PostMetadata>
                            <Avatar author={blogPost.author} />
                            <div><DateIcon /> <span>{date}</span></div>
                            <div><TimeIcon /> <span>{post.timeToRead} {blogPost.lang === 'pl' ? 'min czytania' : 'min read'}</span></div>
                        </PostMetadata>
                        <OpenTableOfContentsFloating darkModeOn={darkMode} showTableOfContents={showTableOfContents} setShowTableOfContents={setShowTableOfContents} />
                        <OpenTableOfContentsTop darkModeOn={darkMode} setShowTableOfContents={setShowTableOfContents} showTableOfContents={showTableOfContents} />
                        <MDXRenderer>{post.body}</MDXRenderer>
                    </article>
                    <hr />
                    <SocialMediaShareButtons url={blogPost.url} />
                    <hr />
                    <Comments />
                </ArticleContainer>
            </MDXProvider>
        </BlogPostLayout>
    )
};

export const query = graphql`
    query BLOG_POST_TEMPLATE_QUERY ($slug: String!) {
        mdx (fields: {slug: { eq: $slug}}) {
            frontmatter {
                title
                description
                author
                url
                date
                lang
                featuredImage {
                    childImageSharp {
                        fluid ( maxHeight: 300, maxWidth: 1500, cropFocus: CENTER) {
                            ...GatsbyImageSharpFluid
                        }
                        resize(width: 1200) {
                            src
                            height
                            width
                        }
                    }
                }
            }
            excerpt
            body
            timeToRead
            fields {
                slug
            }
        }
    }
`;

export default BlogTemplate;