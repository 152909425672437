import React, { useEffect } from "react";
import styled from 'styled-components';

export const CommentsWrapperStyles = styled.div`
    max-width: 65ch;
    margin: 0 auto;
    padding: 4rem 1rem;
    box-sizing: content-box;

    h2 {
        margin: 0;
    }
`;

export function Comments() {

    const COMMENTS_ID = 'graphcomment';

    useEffect(() => {
        window.gc_params = {
            graphcomment_id: 'panodstronyPL',
            fixed_header_height: 0,
        };

        const script = document.createElement('script');
        script.src = 'https://graphcomment.com/js/integration.js?' + Date.now();
        script.async = true;

        const comments = document.getElementById(COMMENTS_ID);
        if (comments) comments.appendChild(script);

        // This function will get called when the component unmounts
        // To make sure we don't end up with multiple instances of the comments component
        return () => {
            const comments = document.getElementById(COMMENTS_ID);
            if (comments) comments.innerHTML = '';
        };
    }, []);

    return (
        <CommentsWrapperStyles>
            <h2>Komentarze</h2>
            <div id={COMMENTS_ID} />
        </CommentsWrapperStyles>
    )
}
