import React from 'react';
import ReactSwitch from "react-switch";
import MoonIcon from "../../../static/moon2.svg";
import SunIcon from "../../../static/sun2.svg";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Logo from "../Logo"

const BlogPostNavigation = styled.header`
    position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
    background: var(--white);
    padding: 16px 0;
    width: 100%;
    display: flex;

    @supports (backdrop-filter: blur(15px)) {
        & {
            backdrop-filter: blur(15px);
            -webkit-backdrop-filter: blur(15px);
            background: rgba(255,255,255,0.8);
        }    
    }
    
    .theme_toggler_wrapper {
        .react-switch-bg > div {
            display: grid;
            place-items: center;

            svg {
                height: 60%;
                width: auto;
                cursor: pointer;
                transition: transform .3s ease-out;

                &:hover {
                    transform: scale(1.2);
                }
            }
        }
    }
    
    &.dark {
        background: #343e4b;
    }
    
    .container {
        display: flex;
            align-items: center;
            justify-content: space-between;
            grid-gap: 32px;
        text-align: center;
        max-width: 65ch;
        width: 100%;
        margin: 0 auto;
    }
    
    h1 {
        margin: 0;
        color: var(--black);
        font-size: 1.5rem;
    }
    
    a {
        display: grid;
    }
    
    @media (max-width: 768px) {
        //display: grid;
        //    grid-template-columns: auto 1fr;
        //    grid-gap: 16px;
        justify-content: space-between;
        padding: 1rem .9rem;
        
        h1 {
            font-size: 90%;
        }
    }
`

export default function BlogPostHeader({ darkModeOn, toggleDarkMode, windowGlobal }) {

    const { t } = useTranslation();

    const uncheckedIcon = <MoonIcon />
    const checkedIcon = <SunIcon />

    const handleToggle = () => {
        toggleDarkMode(!darkModeOn);
        windowGlobal.localStorage.setItem('dark-mode', JSON.stringify(!darkModeOn));
    };

    return (
        <BlogPostNavigation className={darkModeOn && 'dark'}>
            <div className="container">
                <Logo />
                <div className="theme_toggler_wrapper">
                    <ReactSwitch
                        className={darkModeOn ? 'dark' : 'light'}
                        aria-label={t('blog_post.switch_dark_mode')}
                        title={t('blog_post.switch_dark_mode')}
                        checkedIcon={checkedIcon}
                        uncheckedIcon={uncheckedIcon}
                        onChange={handleToggle}
                        checked={darkModeOn}
                        onColor="#f0f0f0"
                        onHandleColor="#9f9f9f"
                        offColor="#111111"
                        offHandleColor="#8f8f8f"
                        // onColor="#2b052c"
                        // offHandleColor="#7285c1"
                        // offColor="#004272"
                        // onHandleColor="#a58aa5"
                    />
                </div>
            </div>
        </BlogPostNavigation>
    )
}