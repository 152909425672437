import { css } from 'styled-components'

export const SocialShareButtonStyles = css`
    display: inline-block;
    width: max-content;
    margin: 0 1rem 1rem 0;
    cursor: pointer;
    color: white;
        text-decoration: none;

    .resp-sharing-button__link,
    .resp-sharing-button__icon {
        display: flex;
    }

    .resp-sharing-button__link {
        text-decoration: none;
        color: #fff;
        margin: 0.5em
    }

    .resp-sharing-button {
        border-radius: 5px;
        transition: 25ms ease-out;
        padding: 0.5em 0.75em;
        display: flex;
        align-items: center;
    }

    .resp-sharing-button__icon svg {
        width: 1em;
        height: 1em;
        margin-right: 0.4em;
        vertical-align: top
    }

    .resp-sharing-button--small svg {
        margin: 0;
        vertical-align: middle
    }

    .resp-sharing-button__icon {
        stroke: #fff;
        fill: none;
        display: flex;
    }

    .resp-sharing-button__icon--solid,
    .resp-sharing-button__icon--solidcircle {
        fill: #fff;
        stroke: none
    }

    .resp-sharing-button--twitter {
        background-color: #55acee
    }

    .resp-sharing-button--twitter:hover {
        background-color: #2795e9
    }

    .resp-sharing-button--pinterest {
        background-color: #bd081c
    }

    .resp-sharing-button--pinterest:hover {
        background-color: #8c0615
    }

    .resp-sharing-button--facebook {
        background-color: #3b5998
    }

    .resp-sharing-button--facebook:hover {
        background-color: #2d4373
    }

    .resp-sharing-button--tumblr {
        background-color: #35465C
    }

    .resp-sharing-button--tumblr:hover {
        background-color: #222d3c
    }

    .resp-sharing-button--reddit {
        background-color: #5f99cf
    }

    .resp-sharing-button--reddit:hover {
        background-color: #3a80c1
    }

    .resp-sharing-button--google {
        background-color: #dd4b39
    }

    .resp-sharing-button--google:hover {
        background-color: #c23321
    }

    .resp-sharing-button--linkedin {
        background-color: #0077b5
    }

    .resp-sharing-button--linkedin:hover {
        background-color: #046293
    }

    .resp-sharing-button--email {
        background-color: #777
    }

    .resp-sharing-button--email:hover {
        background-color: #5e5e5e
    }

    .resp-sharing-button--xing {
        background-color: #1a7576
    }

    .resp-sharing-button--xing:hover {
        background-color: #114c4c
    }

    .resp-sharing-button--whatsapp {
        background-color: #25D366
    }

    .resp-sharing-button--whatsapp:hover {
        background-color: #1da851
    }

    .resp-sharing-button--hackernews {
        background-color: #FF6600
    }
    .resp-sharing-button--hackernews:hover, .resp-sharing-button--hackernews:focus {   background-color: #FB6200 }

    .resp-sharing-button--vk {
        background-color: #507299
    }

    .resp-sharing-button--vk:hover {
        background-color: #43648c
    }

    .resp-sharing-button--facebook {
        background-color: #3b5998;
        border-color: #3b5998;
    }

    .resp-sharing-button--facebook:hover,
    .resp-sharing-button--facebook:active {
        background-color: #2d4373;
        border-color: #2d4373;
    }

    .resp-sharing-button--twitter {
        background-color: #55acee;
        border-color: #55acee;
    }

    .resp-sharing-button--twitter:hover,
    .resp-sharing-button--twitter:active {
        background-color: #2795e9;
        border-color: #2795e9;
    }

    .resp-sharing-button--email {
        background-color: #777777;
        border-color: #777777;
    }

    .resp-sharing-button--email:hover,
    .resp-sharing-button--email:active {
        background-color: #5e5e5e;
        border-color: #5e5e5e;
    }

    .resp-sharing-button--linkedin {
        background-color: #0077b5;
        border-color: #0077b5;
    }

    .resp-sharing-button--linkedin:hover,
    .resp-sharing-button--linkedin:active {
        background-color: #046293;
        border-color: #046293;
    }

    .resp-sharing-button--telegram {
        background-color: #54A9EB;
    }

    .resp-sharing-button--telegram:hover {
        background-color: #4B97D1;}
`;
