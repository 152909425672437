import React, { useEffect } from "react"
import styled from 'styled-components';
import { gsap } from "gsap"
import TableOfContentsIcon from '../../../../static/utility_icons/table_of_contents.svg';

const StyledDiv = styled.div`
    position: fixed;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
    display: flex;
        justify-content: flex-end;
    max-width: 65ch;
    width: 100%;
    margin: 0 auto;
    padding-right: 1rem;

    button {
        display: inline-flex;
        padding: 0.5em;
        //background: var(--primary);
        background: ${({ darkMode }) => darkMode ? 'var(--white)' : 'var(--black)'};
        border: none;
        // background: #004272;
        // border: 1px solid #7285c1;
        border-radius: 50%;
        color: var(--white);
        cursor: pointer;

        svg {
            width: 30px;
            height: 30px;
            display: flex;
            transform: rotate(180deg);
            fill: ${({ darkMode }) => darkMode ? 'var(--black)' : 'var(--white)'};
        }
    }

    //&.dark button {
    //    background: var(--blogPostDarkColorPrimary);
    //    border: 1px solid var(--blogPostDarkColorSecondary);
    //}
`;

export default function OpenTableOfContentsFloating({ setShowTableOfContents, showTableOfContents, darkModeOn }) {

    useEffect(() => {
        gsap.fromTo('.open_table_of_contents_wrapper', { autoAlpha: 0, y: '100%' }, { duration: 5, ease: 'power4.out',
            scrollTrigger: {
                trigger: 'article.blog-post',
                start: '200px, 0%',
                end: '100%, 100%',
                onEnter: () => gsap.to('.open_table_of_contents_wrapper', { y: 0, autoAlpha: 1 }),
                onEnterBack: () => gsap.to('.open_table_of_contents_wrapper', { y: 0, autoAlpha: 1 }),
                onLeaveBack: () => gsap.to('.open_table_of_contents_wrapper', { y: '100%', autoAlpha: 0 }),
                onLeave: () => gsap.to('.open_table_of_contents_wrapper', { y: '100%', autoAlpha: 0 }),
            }
        })
    }, []);

    return (
        <StyledDiv darkMode={darkModeOn} className={darkModeOn ? 'open_table_of_contents_wrapper dark' : 'open_table_of_contents_wrapper'}>
            <button onClick={() => setShowTableOfContents(!showTableOfContents)}>
                <TableOfContentsIcon />
            </button>
        </StyledDiv>
    )
}


