import React from 'react';
import styled from 'styled-components';
import TableOfContentsIcon from '../../../../static/utility_icons/table_of_contents.svg';

export const StyledButton = styled.button`
    display: flex;
        align-items: center;
    background: none;
    color: inherit;
    font-weight: bold;
    cursor: pointer;
    border: ${({ darkMode }) => darkMode ? '2px solid var(--white)' : '2px solid var(--black)' };
    padding: .2em .7em .2em 0.6em;
    border-radius: 7px;
    transition: .3s ease-out background-color, .3s ease-out color;
    //text-decoration: underline var(--primary) 2px;
    //    text-decoration-skip-ink: none;
    // font-size: inherit;
    
    &:hover {
        background: ${({ darkMode }) => darkMode ? 'var(--white)' : 'var(--black)' };
        color: ${({ darkMode }) => darkMode ? 'var(--black)' : 'var(--white)' };
        
        svg {
            fill: ${({ darkMode }) => darkMode ? 'var(--black)' : 'var(--white)'};
        }
    }
    
    svg {
        fill: ${({ darkMode }) => darkMode ? 'var(--white)' : 'var(--black)'};
        height: 35px;
        width: auto;
        transform: rotate(180deg);
        transition: .3s ease-out fill;
    }
`;

export default function OpenTableOfContentsTop({ setShowTableOfContents, showTableOfContents, darkModeOn }) {

    return (
        <StyledButton
            className={darkModeOn ? 'open_table_of_contents_button_top dark' : 'open_table_of_contents_button_top'}
            onClick={() => setShowTableOfContents(!showTableOfContents)}
            darkMode={darkModeOn}
        >
            <TableOfContentsIcon /> Spis treści
        </StyledButton>
    )
}