import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components";

const StyledAvatar = styled.div`
    span {
        margin-left: .5em;
    }
`;

export default function Avatar({ author }) {
    return (
        <StyledAvatar>
            <StaticImage
                src="../../../static/my_avatar_3.png"
                alt={author}
                width={35}
                formats={["auto", "webp", "png"]}
                quality={100}
            />
            <span>{author}</span>
        </StyledAvatar>
    )
}