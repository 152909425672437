import React from 'react';
import styled from 'styled-components';
import FacebookShare from "./FacebookShare"
import TwitterShare from "./TwitterShare"
import LinkedInShare from "./LinkedInShare"
import EmailShare from "./EmailShare"

const StyledSocialShareButtonsWrapper = styled.section`
    display: block;
    max-width: 65ch;
    margin: 4rem auto 4rem;
    color: inherit;
    //display: grid;
    //    grid-template-columns: repeat(auto-fill,minmax(10rem,1fr));
    
    //a:not(:last-child) {
    //    margin-right: 1rem; 
    //}
    
    @media (max-width: 768px) {
        padding: 0 1rem;
    }
`;

const SocialMediaShareButtons = ({ children, url }) => (
    <StyledSocialShareButtonsWrapper>
        <p>Dziękuję za dotarcie do końca. Jeżeli spodobał Ci się ten artykuł, może rozważysz udostępnienie go swoim znajomym?</p>
        <FacebookShare hrefUrl={url} />
        <TwitterShare hrefUrl={url} />
        <LinkedInShare hrefUrl={url} />
        <EmailShare hrefUrl={url} />
        {children}
    </StyledSocialShareButtonsWrapper>
);

export default SocialMediaShareButtons;